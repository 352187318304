const ProgressRing = ({ radius, stroke, progress }) => {
	const normalizedRadius = radius - stroke * 2;
	const circumference = normalizedRadius * 2 * Math.PI;
	const strokeDashoffset = circumference - (progress / 100) * circumference;

	return (
		<svg
			height={radius * 2}
			width={radius * 2}
			className="text-teal-500"
		>
			{/* Background circle in light grey */}
			<circle
				stroke="#D3D3D3" // Light grey color
				fill="transparent"
				strokeWidth={stroke}
				r={normalizedRadius}
				cx={radius}
				cy={radius}
			/>
			{/* Foreground circle in teal */}
			<circle
				stroke="currentColor"
				fill="transparent"
				strokeWidth={stroke}
				strokeDasharray={circumference + ' ' + circumference}
				style={{ strokeDashoffset }}
				r={normalizedRadius}
				cx={radius}
				cy={radius}
			/>
		</svg>
	);
};

export default ProgressRing;
