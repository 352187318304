import React from 'react';
import './App.css';
import Questionnaire from './Questionnaire';

function App() {
  return (
    <div className="App">
      <header className="App-header bg-gradient-to-r from-cyan-950 via-sky-950 to-blue-950 h-screen">
        <Questionnaire />
      </header>
    </div>
  );
}

export default App;
