import {useCallback} from 'react';
import Particles from "react-tsparticles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.

export const ParticlesBackground = ({opacity}) => {
	const particlesInit = useCallback(async engine => {
		// you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
		// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
		// starting from v2 you can add only the features you need reducing the bundle size
		//await loadFull(engine);
		await loadSlim(engine);
	}, []);

	const particlesLoaded = useCallback(async container => {}, []);

	return (
		<Particles
			className="absolute top-0 left-0 w-full h-full z-0"
			id="tsparticles"
			init={particlesInit}
			loaded={particlesLoaded}
			options={{
				// background: {
				// 	color: {
				// 		value: "#0d47a1",
				// 	},
				// },
				fpsLimit: 120,
				// interactivity: {
				// 	events: {
				// 		onClick: {
				// 			enable: true,
				// 			mode: "push",
				// 		},
				// 		onHover: {
				// 			enable: true,
				// 			mode: "repulse",
				// 		},
				// 		resize: true,
				// 	},
				// 	modes: {
				// 		push: {
				// 			quantity: 4,
				// 		},
				// 		repulse: {
				// 			distance: 200,
				// 			duration: 0.4,
				// 		},
				// 	},
				// },
				particles: {
					color: {
						value: "#ffffff",
					},
					links: {
						color: "#ffffff",
						distance: 150,
						enable: true,
						opacity: opacity,
						width: 1,
					},
					move: {
						direction: "none",
						enable: true,
						outModes: {
							default: "bounce",
						},
						random: false,
						speed: 1,
						straight: false,
					},
					number: {
						density: {
							enable: true,
							area: 800,
						},
						value: 80,
					},
					opacity: {
						value: opacity,
					},
					shape: {
						type: "circle",
					},
					size: {
						value: { min: 1, max: 5 },
					},
				},
				detectRetina: true,
			}}
		/>
	)
}