import React, { useState } from 'react';
import { MagicMotion, m } from 'react-magic-motion';
import CompanyLogo from './logo.png'; // Path to your logo
import ProgressRing from './progressring';
import { ParticlesBackground } from './particles';

const Questionnaire = () => {
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [answers, setAnswers] = useState({});
	const [begin, setBegin] = useState(false);

	const [scores, setScores] = useState(null);

	const [showContactForm, setShowContactForm] = useState(false);
	const [opacity, setOpacity] = useState(0.1); // Added opacity state variable

	const questions = [
		// AI Preparedness
		{
			id: 1,
			text: 'Does your organization have a dedicated budget for AI initiatives?',
			options: ['A) Yes, we have a substantial budget', 'B) Yes, but it\'s quite limited', 'C) No, but we plan to allocate one', 'D) No, and no plans currently']
		},
		{
			id: 2,
			text: 'How would you describe the level of top management support for AI in your organization?',
			options: ['A) Strong and active support', 'B) Supportive but not actively involved', 'C) Indifferent or unaware', 'D) Skeptical or resistant']
		},
		{
			id: 3,
			text: 'How do you rate your organization\'s data management capabilities?',
			options: ['A) Advanced (well-organized and easily accessible data)', 'B) Moderate (some organized data, but not fully optimized)', 'C) Basic (data is collected but not well-managed)', 'D) Poor (little to no data collection)']
		},
		{
			id: 4,
			text: 'What is the state of your IT infrastructure in terms of supporting AI technologies?',
			options: ['A) Fully equipped and scalable', 'B) Adequate but requires upgrades', 'C) Outdated and needs significant investment', 'D) Non-existent or unknown']
		},
		{
			id: 5,
			text: 'Does your organization have a clear AI strategy and roadmap?',
			options: ['A) Yes, it\'s well-defined and being executed', 'B) Yes, but it\'s not fully implemented yet', 'C) In development', 'D) No, we don\'t have one']
		},

		// AI Proficiency
		{
			id: 6,
			text: 'What is the level of AI knowledge and expertise within your organization?',
			options: ['A) High (several AI experts and ongoing training)', 'B) Moderate (some knowledgeable individuals)', 'C) Basic (limited understanding)', 'D) None']
		},
		{
			id: 7,
			text: 'How often does your organization engage in AI training and development?',
			options: ['A) Regularly', 'B) Occasionally', 'C) Rarely', 'D) Never']
		},
		{
			id: 8,
			text: 'To what extent are AI and machine learning integrated into your business processes?',
			options: ['A) Extensively in multiple areas', 'B) In a few key areas', 'C) Very little integration', 'D) Not at all']
		},
		{
			id: 9,
			text: 'How would you describe your staff\'s attitude towards AI and new technologies?',
			options: ['A) Highly enthusiastic and adaptive', 'B) Somewhat interested but cautious', 'C) Indifferent or hesitant', 'D) Resistant or fearful']
		},
		{
			id: 10,
			text: 'Does your organization have access to AI experts or consultants?',
			options: ['A) Yes, we have in-house experts or regular consultants', 'B) Yes, but only on an occasional basis', 'C) No, but we are considering it', 'D) No, and we are not considering it']
		},

		// AI Culture Readiness
		{
			id: 11,
			text: 'How is AI perceived within your organization?',
			options: ['A) As a critical strategic tool', 'B) As a useful but not essential tool', 'C) As a potential future tool', 'D) As irrelevant or unimportant']
		},
		{
			id: 12,
			text: 'How does your organization view failure in AI projects?',
			options: ['A) As a learning opportunity', 'B) With some tolerance but generally unfavorably', 'C) Negatively, often leading to reluctance in future projects', 'D) We haven\'t had any AI projects']
		},
		{
			id: 13,
			text: 'Is there a culture of innovation and experimentation in your organization?',
			options: ['A) Strongly yes', 'B) To some extent', 'C) Rarely', 'D) No']
		},
		{
			id: 14,
			text: 'How collaborative are different departments in your organization when it comes to AI initiatives?',
			options: ['A) Highly collaborative', 'B) Somewhat collaborative', 'C) Not very collaborative', 'D) Siloed and non-collaborative']
		},
		{
			id: 15,
			text: 'Does your organization actively seek to stay updated on AI trends and advancements?',
			options: ['A) Yes, consistently', 'B) Yes, but not systematically', 'C) Occasionally', 'D) Rarely or never']
		},

		// AI Efficiency Potential
		{
			id: 16,
			text: 'How do you assess the potential impact of AI on your organization\'s efficiency?',
			options: ['A) Transformative', 'B) Significant', 'C) Moderate', 'D) Limited']
		},
		{
			id: 17,
			text: 'Are there clear use cases or applications for AI identified in your organization?',
			options: ['A) Multiple and well-defined', 'B) A few identified', 'C) Not specifically identified', 'D) None']
		},
		{
			id: 18,
			text: 'How does your organization approach the integration of AI with existing systems?',
			options: ['A) Proactively and with a clear plan', 'B) Reactively as needs arise', 'C) With hesitation or uncertainty', 'D) We do not integrate AI']
		},
		{
			id: 19,
			text: 'What is the level of customization in your AI solutions?',
			options: ['A) Highly customized to our needs', 'B) Somewhat customized', 'C) Off-the-shelf solutions with little customization', 'D) We don\'t use AI solutions']
		},
		{
			id: 20,
			text: 'How does your organization measure the success of AI implementations?',
			options: ['A) Through clear KPIs and ROI metrics', 'B) Anecdotally or through qualitative feedback', 'C) We don\'t have measurements in place', 'D) We haven\'t implemented AI']
		},
	];

	const handleBeginClick = () => {
		setOpacity(0.1);
		setBegin(!begin); // Navigate to the questionnaire page
	};


	const handleOptionChange = (option) => {
		const question = questions[currentQuestionIndex];
		setAnswers(prevAnswers => ({
			...prevAnswers,
			[question.id]: option
		}));
	};


	const goToNextQuestion = () => {
		if (currentQuestionIndex < questions.length - 1) {
			const question = questions[currentQuestionIndex];
			if (answers[question.id] === undefined) {
				// Handle the case where no answer is selected
				// You might want to alert the user or handle this case appropriately
				alert('Please select an answer before proceeding')
				return;
			}
			setCurrentQuestionIndex(currentQuestionIndex + 1);
		}
	};


	const goToPreviousQuestion = () => {
		if (currentQuestionIndex > 0) {
			setCurrentQuestionIndex(currentQuestionIndex - 1);
		}
	};

	const calculateScores = (answers) => {
		// Define point values for each answer option
		const points = {
			'A': 4,
			'B': 3,
			'C': 2,
			'D': 1
		};

		// Initialize scores for each aspect
		let aiPreparedness = 0;
		let aiProficiency = 0;
		let aiCultureReadiness = 0;
		let aiEfficiencyPotential = 0;

		// Calculate scores for each aspect
		Object.entries(answers).forEach((answer, index) => {
			const score = points[answer[1].charAt(0)]; // Assuming answer is like 'A) ...'

			if (index < 5) { // AI Preparedness questions
				aiPreparedness += score;
			} else if (index < 10) { // AI Proficiency questions
				aiProficiency += score;
			} else if (index < 15) { // AI Culture Readiness questions
				aiCultureReadiness += score;
			} else { // AI Efficiency Potential questions
				aiEfficiencyPotential += score;
			}
		});

		// Assuming each category has 5 questions, maximum score per category is 5 questions * 4 points
		const maxScorePerCategory = 5 * 4;

		// Convert raw scores to percentages
		const aiPreparednessPercentage = (aiPreparedness / maxScorePerCategory) * 100;
		const aiProficiencyPercentage = (aiProficiency / maxScorePerCategory) * 100;
		const aiCultureReadinessPercentage = (aiCultureReadiness / maxScorePerCategory) * 100;
		const aiEfficiencyPotentialPercentage = (aiEfficiencyPotential / maxScorePerCategory) * 100;

		// Return the calculated scores as percentages
		return {
			aiPreparedness: aiPreparednessPercentage,
			aiProficiency: aiProficiencyPercentage,
			aiCultureReadiness: aiCultureReadinessPercentage,
			aiEfficiencyPotential: aiEfficiencyPotentialPercentage
		};
	};



	const handleFinishSurvey = async (event) => {
		event.preventDefault();
		setShowContactForm(true);
	};

	const handleContactFormSubmit = async (contactInfo) => {
		// Webhook endpoint
		const webhookEndpoint = process.env.REACT_APP_WEBHOOK_ENDPOINT;

		// Prepare the data in the format required by the webhook
		const webhookData = {
			firstName: contactInfo.firstName,
			lastName: contactInfo.lastName,
			jobTitle: contactInfo.jobTitle,
			companyName: contactInfo.companyName,
			email: contactInfo.email,
			phone: contactInfo.phone
		};

		try {
			const response = await fetch(webhookEndpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(webhookData)
			});

			if (!response.ok) {
				throw new Error(`Error: ${response.status}`);
			}

			let responseData;
			const contentType = response.headers.get("content-type");
			if (contentType && contentType.indexOf("application/json") !== -1) {
				responseData = await response.json();
			} else {
				responseData = await response.text();
			}

			console.log('Contact info submitted:', responseData);

			const calculatedScores = calculateScores(answers);
			setScores(calculatedScores);
		} catch (error) {
			console.error('Failed to submit contact info:', error);
		}
	};



	const currentQuestion = questions[currentQuestionIndex];

	const ConcludingComponent = ({ scores }) => {
		const interpretScore = (score) => {
			if (score >= 75) {
				return 'Excellent - Your organization is well-prepared and shows strong potential in this area.';
			} else if (score >= 50) {
				return 'Good - There are positive aspects, but there\'s room for improvement.';
			} else if (score >= 25) {
				return 'Fair - Some foundational elements are in place, but significant development is needed.';
			} else {
				return 'Needs Improvement - This is a critical area to focus on for future development.';
			}
		};

		const ScoreCard = ({ title, score, explanation }) => (
			<div className="flex flex-col items-center p-4 border rounded-lg">
				<h3 className="font-bold mb-2">{title}</h3>
				<p className="text-sm my-2">{explanation}</p>
				<div className="relative">
					<ProgressRing radius={70} stroke={5} progress={parseFloat(score)} />
					<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-2xl font-bold">
						{parseFloat(score).toFixed(0)}%
					</div>
				</div>
				<p className="mt-1">{interpretScore(score)}</p>
			</div>
		);

		return (
			<div className="px-4 my-8">
				<h2 className="text-5xl font-bold mb-2">Your Organization's AI Adoption Scores</h2>
				<p className="text-md mt-8 mb-12">We also sent you a copy to the email you provided, so you can share the results with your team!</p>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
					<ScoreCard
						title="AI Preparedness"
						score={scores.aiPreparedness}
						explanation="How prepared is your organization to leverage AI tools and technology."
					/>
					<ScoreCard
						title="AI Proficiency"
						score={scores.aiProficiency}
						explanation="Your organization's proficiency in utilizing AI for effective outcomes."
					/>
					<ScoreCard
						title="AI Culture Readiness"
						score={scores.aiCultureReadiness}
						explanation="The readiness of your organization's culture to adopt and integrate AI."
					/>
					<ScoreCard
						title="AI Efficiency Potential"
						score={scores.aiEfficiencyPotential}
						explanation="Potential for AI to enhance efficiency in your organization's processes."
					/>
				</div>
			</div>
		);
	};

	const ContactForm = ({ onSubmit }) => {
		const [contactInfo, setContactInfo] = useState({
			firstName: '',
			lastName: '',
			jobTitle: '',
			email: '',
			phone: '',
			companyName: '' // Added company name field
		});

		const [validationErrors, setValidationErrors] = useState({
			firstName: '',
			lastName: '',
			jobTitle: '',
			email: '',
			phone: '',
			companyName: ''
		});

		const validate = () => {
			let errors = {};
			if (contactInfo.firstName.length <= 1) {
				errors.firstName = 'First name must be longer than 1 characters.';
			}
			if (contactInfo.lastName.length <= 1) {
				errors.name = 'Last name must be longer than 1 characters.';
			}
			if (contactInfo.jobTitle.length <= 1) {
				errors.name = 'Job Title must be longer than 1 characters.';
			}
			if (contactInfo.companyName.length <= 2) {
				errors.companyName = 'Company name must be longer than 2 characters.';
			}
			if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contactInfo.email)) {
				errors.email = 'Invalid email format.';
			}
			if (!/^\+?([0-9]{1,3})?([0-9]{10})$/.test(contactInfo.phone)) {
				errors.phone = 'Invalid phone number format.';
			}
			setValidationErrors(errors);
			return Object.keys(errors).length === 0;
		};

		const handleChange = (e) => {
			setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
		};

		const handleSubmit = (e) => {
			e.preventDefault();
			if (validate()) {
				setShowContactForm(false);
				onSubmit(contactInfo);
			}
		};

		return (
			<MagicMotion
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { duration: 1 } }}
				exit={{ opacity: 0, transition: { duration: 1 } }}
			>
				<form onSubmit={handleSubmit}>
					<h2 className="text-5xl font-semibold mb-8">Discover Your AI Adoption Score</h2>
					<p className="text-xl mb-8">Fill in your details below and we'll send you the insights.</p>
					<div className="mb-4">
						<label htmlFor="firstName" className="block text-white text-sm font-bold mb-2">First Name:</label>
						<input type="text" name="firstName" value={contactInfo.firstName} onChange={handleChange} className="border-white border rounded w-full py-2 px-3 text-white bg-transparent leading-tight focus:outline-none focus:shadow-outline" />
						{validationErrors.firstName && <p className="text-red-500 text-xs italic">{validationErrors.firstName}</p>}
					</div>
					<div className="mb-4">
						<label htmlFor="lastName" className="block text-white text-sm font-bold mb-2">Last Name:</label>
						<input type="text" name="lastName" value={contactInfo.lastName} onChange={handleChange} className="border-white border rounded w-full py-2 px-3 text-white bg-transparent leading-tight focus:outline-none focus:shadow-outline" />
						{validationErrors.lastName && <p className="text-red-500 text-xs italic">{validationErrors.lastName}</p>}
					</div>
					<div className="mb-4">
						<label htmlFor="jobTitle" className="block text-white text-sm font-bold mb-2">Job Title:</label>
						<input type="text" name="jobTitle" value={contactInfo.jobTitle} onChange={handleChange} className="border-white border rounded w-full py-2 px-3 text-white bg-transparent leading-tight focus:outline-none focus:shadow-outline" />
						{validationErrors.jobTitle && <p className="text-red-500 text-xs italic">{validationErrors.jobTitle}</p>}
					</div>
					<div className="mb-4">
						<label htmlFor="companyName" className="block text-white text-sm font-bold mb-2">Company Name:</label>
						<input type="text" name="companyName" value={contactInfo.companyName} onChange={handleChange} className="border-white border rounded w-full py-2 px-3 text-white bg-transparent leading-tight focus:outline-none focus:shadow-outline" />
						{validationErrors.companyName && <p className="text-red-500 text-xs italic">{validationErrors.companyName}</p>}
					</div>
					<div className="mb-4">
						<label htmlFor="email" className="block text-white text-sm font-bold mb-2">Email:</label>
						<input type="email" name="email" value={contactInfo.email} onChange={handleChange} className="border-white border rounded w-full py-2 px-3 text-white bg-transparent leading-tight focus:outline-none focus:shadow-outline" />
						{validationErrors.email && <p className="text-red-500 text-xs italic">{validationErrors.email}</p>}
					</div>
					<div className="mb-4">
						<label htmlFor="phone" className="block text-white text-sm font-bold mb-2">Phone:</label>
						<input type="tel" name="phone" value={contactInfo.phone} onChange={handleChange} className="border-white border rounded w-full py-2 px-3 text-white bg-transparent leading-tight focus:outline-none focus:shadow-outline" />
						{validationErrors.phone && <p className="text-red-500 text-xs italic">{validationErrors.phone}</p>}
					</div>
					<button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Show me my scores!</button>
				</form>

			</MagicMotion>
		);
	};

	return (
		<div className="container mx-auto p-4 py-2">
			{!begin && !scores && !showContactForm &&
				<div className='relative'>
					<ParticlesBackground opacity={opacity} />
					<div className='relative z-10'>
						<div className="flex flex-col items-center justify-center text-center min-h-screen">
							<img src={CompanyLogo} alt="Company Logo" className="mb-8 h-20 md:h-32 lg:mb-12" />
							<h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 text-white">20 Questions to Unlock Your Organization's AI Adoption Potential</h1>
							<button onClick={handleBeginClick} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-6">
								Start the survey now and get your personalized AI Adoption Score!
							</button>
							<p className="text-base md:text-lg lg:text-xl mb-6 md:mb-8 text-white">Discover how ready you and your organization are for AI integration. Take our quick survey to assess your AI Preparedness, Proficiency, Culture Readiness, and Efficiency Potential.</p>
							<p className="text-base md:text-lg lg:text-xl mb-6 md:mb-8 text-white">Join the ranks of forward-thinking professionals and organizations. See where you stand in the AI journey.</p>
							{/* <ul className="text-base md:text-lg lg:text-xl mb-6 md:mb-8 text-left text-white">
								<li>AI Preparedness: "Evaluate your readiness for adopting AI technologies."</li>
								<li>AI Proficiency: "Assess your current skills and knowledge in AI."</li>
								<li>AI Culture Readiness: "Understand how well your organizational culture supports AI adoption."</li>
								<li>AI Efficiency Potential: "Discover the potential impact of AI on your operational efficiency."</li>
							</ul> */}
							<p className="text-base md:text-lg lg:text-xl mb-6 md:mb-8 italic text-white">* Your responses are confidential and will be used solely for assessing your AI readiness.</p>
						</div>
					</div>
				</div>}
			{showContactForm && <ContactForm onSubmit={handleContactFormSubmit} />}
			{scores && !showContactForm && <ConcludingComponent scores={scores} />}
			{begin && !scores && !showContactForm &&
				<form onSubmit={handleFinishSurvey}>
					<MagicMotion>
						<div className="mb-4">
							<m.p
								className="text-2xl md:text-4xl lg:text-5xl font-semibold mb-12 md:mb-16 lg:mb-24"
								initial={{ opacity: 0, y: -10 }}
								animate={{ opacity: 1, y: 0, transition: { duration: 1 } }}
								exit={{ opacity: 0, transition: { duration: 1 } }}
								key={currentQuestion.id}
							>
								{currentQuestion.text}
							</m.p>

							<div className="flex flex-col mb-12 md:mb-16 lg:mb-24">
								{currentQuestion.options.map((option) => (
									<m.label
										key={option}
										className={`block rounded-md p-2 md:p-3 mt-2 cursor-pointer border transition duration-500 ${answers[currentQuestion.id] === option
											? 'bg-teal-500 border-teal-500 text-white'
											: 'border-white text-white bg-transparent hover:bg-off-white hover:text-dark-gray'
											}`}
										initial={{ opacity: 0 }}
										animate={{ opacity: 1, transition: { duration: 1 } }}
										exit={{ opacity: 0, transition: { duration: 1 } }}
									>
										<input
											type="radio"
											name={`question-${currentQuestion.id}`}
											value={option}
											checked={answers[currentQuestion.id] === option}
											onChange={() => handleOptionChange(option)}
											className="hidden" // Hide the radio button
										/>
										<span>{option}</span>
									</m.label>
								))}
							</div>
						</div>
					</MagicMotion>
					<div className="flex justify-between">
						<button
							type="button"
							onClick={goToPreviousQuestion}
							className={`bg-gray-500 text-white font-bold py-2 px-4 rounded ${currentQuestionIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-700'}`}
							disabled={currentQuestionIndex === 0}
						>
							Previous
						</button>
						{currentQuestionIndex < questions.length - 1 &&
							<button
								type="button"
								onClick={goToNextQuestion}
								className={`bg-blue-500 text-white font-bold py-2 px-4 rounded ${!answers[currentQuestion.id] ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'}`}
								disabled={!answers[currentQuestion.id]} // Disable if no answer is selected
							>
								Next
							</button>
						}
						{currentQuestionIndex === questions.length - 1 && <button
							type="submit"
							className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
						>
							Finish
						</button>
						}
					</div>
					{/* Progress Bar */}
					<div className="bg-gray-200 rounded-full h-2 mt-8">
						<div
							className="bg-teal-500 h-2 rounded-full"
							style={{
								width: `${(currentQuestionIndex / (questions.length - 1)) * 100}%`,
								transition: 'width 0.5s ease-in-out' // Added transition
							}}
						></div>
					</div>
				</form>
			}
		</div>
	);
};

export default Questionnaire;